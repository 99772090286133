'use client';

import { ReactNode } from 'react';
import { UseCustomerDataResult } from '@lib/machine-parts/storefront/account/data-access';
import { InternationalizationProps, ShopifyStorefrontClientConfig } from '@lib/machine-parts/storefront/utils';
import { ShopifyProvider } from '@shopify/hydrogen-react';
import { CountryCode } from '../../generated/graphql';
import type { LanguageCode } from '@shopify/hydrogen-react/dist/types/storefront-api-types';
import { AuthenticationProvider } from '@lib/machine-parts/storefront/account/feature';
import { CartProvider, CheckoutV2ProviderClient } from '@lib/machine-parts/storefront/checkout/data-access';
import { CheckoutProviderClient } from '../../providers/CheckoutProvider';

export function LayoutProviders({
    children,
    customer,
    customerAccessToken,
    storefrontConfig,
    ...props
}: {
    children: ReactNode;
    customer: UseCustomerDataResult;
    customerAccessToken?: string;
    storefrontConfig: ShopifyStorefrontClientConfig;
} & InternationalizationProps) {
    return (
        <ShopifyProvider
            {...storefrontConfig}
            countryIsoCode={props.locale.toUpperCase() as CountryCode}
            languageIsoCode={props.language.toUpperCase() as LanguageCode}
        >
            <AuthenticationProvider customer={customer} customerAccessToken={customerAccessToken}>
                <CartProvider countryCode={props.locale} languageCode={props.language}>
                    <CheckoutV2ProviderClient countryCode={props.locale as CountryCode} customer={customer}>
                        <CheckoutProviderClient countryCode={props.locale} languageCode={props.language} {...props}>
                            {children}
                        </CheckoutProviderClient>
                    </CheckoutV2ProviderClient>
                </CartProvider>
            </AuthenticationProvider>
        </ShopifyProvider>
    );
}
